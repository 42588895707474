import React, { useState, useEffect } from 'react';
import './App.css';
import logo1 from './images/lords.png';
import logo2 from './images/pesco.png';
import logo3 from './images/grug.png';
import logo4 from './images/miggles.png';
import logo5 from './images/allbase.png';
import logo6 from './images/moby.png';
import logo7 from './images/chomp.png';
import logo8 from './images/doomer.png';
import logo9 from './images/boomer.png';
import logo10 from './images/basedchad.png';
import logo11 from './images/peezy.png';
import logo12 from './images/weirdo.png';
import logo13 from './images/lordy.png';
import logo14 from './images/poncho.png';
import logo15 from './images/basedusa.png';
import logo16 from './images/warpie.png';
import logo17 from './images/waldo.png';
import logo18 from './images/chuck.png';
import logo19 from './images/crash.png';
import logo20 from './images/dog.png';
import logo21 from './images/bario.png';
import logo23 from './images/berf.png';
import logo24 from './images/crow.png';
import logo25 from './images/toshi.png';
import logo26 from './images/basejump.png';
import logo27 from './images/jinx.png';
import logo28 from './images/unlucky.png';
import logo29 from './images/maga.png';
import logo30 from './images/bepe.png';
import logo31 from './images/reward.png';
import logo32 from './images/mcade.png';
import logo33 from './images/bsb.png';
import logo34 from './images/goat.png';
import logo35 from './images/fellas.png';

// Import Coinbase Wallet SDK and Web3
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3 from 'web3';

function App() {
  const [logos, setLogos] = useState([]);
  const [inputValue, setInputValue] = useState(''); // Controlled input state
  const [errorMessage, setErrorMessage] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [scrolled, setScrolled] = useState(false); // State to manage scroll event

  // Coinbase Wallet SDK setup
  const APP_NAME = 'Based Cartel';
  const APP_LOGO_URL = 'https://www.basedcartel.life/logo.png';
  const DEFAULT_ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/a482124e9ec74155b35bdf03c623376f';
  const DEFAULT_CHAIN_ID = 1; // Ethereum mainnet

  const wallet = new CoinbaseWalletSDK({
    appName: APP_NAME,
    appLogoUrl: APP_LOGO_URL,
    darkMode: false,
  });

  const ethereum = wallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID);
  const web3 = new Web3(ethereum);

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    const initialLogos = [
      { src: logo1, alt: 'Logo 1', link: 'https://memelordz.io/' },
      { src: logo2, alt: 'Logo 2', link: 'https://peploescobar.com/' },
      { src: logo3, alt: 'Logo 3', link: 'https://grugnorug.wtf/' },
      { src: logo4, alt: 'Logo 4', link: 'https://mrmigglesbase.com/' },
      { src: logo5, alt: 'Logo 5', link: 'https://www.belongtous.xyz/' },
      { src: logo6, alt: 'Logo 6', link: 'https://www.mobyonbase.com/' },
      { src: logo7, alt: 'Logo 7', link: 'https://www.chompcoin.xyz/' },
      { src: logo8, alt: 'Logo 8', link: 'https://doomercto.xyz/' },
      { src: logo9, alt: 'Logo 9', link: 'https://www.baseboomer.com/' },
      { src: logo10, alt: 'Logo 10', link: 'https://www.basedchad.io/' },
      { src: logo11, alt: 'Logo 11', link: 'https://youngpeezyonbase.com/' },
      { src: logo12, alt: 'Logo 12', link: 'https://www.weirdobase.com/' },
      { src: logo13, alt: 'Logo 13', link: 'https://www.lordy.meme/' },
      { src: logo14, alt: 'Logo 14', link: 'https://www.ponchobase.com/' },
      { src: logo15, alt: 'Logo 15', link: 'https://basedusa.org/' },
      { src: logo16, alt: 'Logo 16', link: 'https://warpie.gg/' },
      { src: logo17, alt: 'Logo 17', link: 'https://www.waldoisbased.com' },
      { src: logo18, alt: 'Logo 18', link: 'https://chuckonbase.io/' },
      { src: logo19, alt: 'Logo 19', link: 'https://www.waldoisbased.com' },
      { src: logo20, alt: 'Logo 20', link: 'https://chuckonbase.io/' },
      { src: logo21, alt: 'Logo 21', link: 'https://bario.buzz/' },
      { src: logo23, alt: 'Logo 23', link: 'https://berfbased.com/' },
      { src: logo24, alt: 'Logo 24', link: 'https://www.crowwithknife.com/' },
      { src: logo25, alt: 'Logo 25', link: 'https://www.toshithecat.com/' },
      { src: logo26, alt: 'Logo 26', link: 'https://www.basejump.pro/' }, 
      { src: logo27, alt: 'Logo 27', link: 'https://www.jinxisbased.com/' },
      { src: logo28, alt: 'Logo 28', link: 'https://unlucky.fun/' },
      { src: logo29, alt: 'Logo 29', link: 'https://magatrumponbase.com/' },
      { src: logo30, alt: 'Logo 30', link: 'https://bepe.live/' },
      { src: logo31, alt: 'Logo 31', link: 'https://rewardable.app' },
      { src: logo32, alt: 'Logo 32', link: 'https://metacade.co/' },
      { src: logo33, alt: 'Logo 33', link: 'https://allstreetbets.com/' },
      { src: logo34, alt: 'Logo 34', link: 'https://goatwifhat.com' },
      { src: logo35, alt: 'Logo 35', link: 'https://basedfellas.io' },   
    ];    

    setLogos(shuffleArray(initialLogos));
  }, []);

  // Connect to the wallet and set the address
  const connectWallet = async () => {
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const walletAddr = accounts[0];

      setWalletAddress(walletAddr);
      setInputValue(walletAddr);
      setIsConnected(true);

      const inputField = document.getElementById('wallet-input');
      if (inputField) {
        inputField.value = walletAddr;
        inputField.dispatchEvent(new Event('input', { bubbles: true }));
      }
    } catch (error) {
      console.error('Error connecting to Coinbase Wallet:', error);
    }
  };

  // Scroll event listener to detect scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (walletAddress) {
      setInputValue(walletAddress);
    }
  }, [walletAddress]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!inputValue.startsWith('0x')) {
      setErrorMessage('Input must be a valid wallet address');
      return;
    }

    try {
      const response = await fetch('https://www.basedcartel.life/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: inputValue }),
      });

      if (response.ok) {
        setErrorMessage('Successfully submitted!');
        setInputValue('');
      } else {
        alert('Failed to submit input.');
      }
    } catch (error) {
      console.error('Error submitting input:', error);
    }
  };

  return (
    <div className="app-container">
      <header>
        <h1>Based Cartel Allowlist</h1>
      </header>

      {/* Social Links and Wallet Connect Section */}
      <div className={`top-bar ${scrolled ? 'scrolled' : ''}`}>
        <div className="social-links">
          <a href="https://x.com/TheBasedCartel" target="_blank" rel="noopener noreferrer">X</a>
          <a href="https://t.me/basedcartel" target="_blank" rel="noopener noreferrer">Telegram</a>
        </div>

        <div className="wallet-connect-button">
          {!isConnected ? (
            <button onClick={connectWallet}>Coinbase Wallet</button>
          ) : (
            <span className="connected-status">Connected</span>
          )}
        </div>
      </div>

      <main>
        <h1 style={{color: 'red', fontSize: '2.5rem', marginTop: '0px'}}>Allowlist Closed</h1>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <p className="collaboration-text">In Collaboration With</p>

        <div className="collaboration-images">
          {logos.map((logo, index) => (
            <a href={logo.link} target="_blank" rel="noreferrer" key={index}>
              <img src={logo.src} alt={logo.alt} />
            </a>
          ))}
        </div>
      </main>
    </div>
  );
}

export default App;
